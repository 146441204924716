// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/dunk-test/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/dunk-test/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-coupons-js": () => import("/opt/build/repo/dunk-test/src/pages/coupons.js" /* webpackChunkName: "component---src-pages-coupons-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/dunk-test/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-flavors-caramel-extra-extra-js": () => import("/opt/build/repo/dunk-test/src/pages/flavors/caramel-extra-extra.js" /* webpackChunkName: "component---src-pages-flavors-caramel-extra-extra-js" */),
  "component---src-pages-flavors-extra-extra-js": () => import("/opt/build/repo/dunk-test/src/pages/flavors/extra-extra.js" /* webpackChunkName: "component---src-pages-flavors-extra-extra-js" */),
  "component---src-pages-flavors-index-js": () => import("/opt/build/repo/dunk-test/src/pages/flavors/index.js" /* webpackChunkName: "component---src-pages-flavors-index-js" */),
  "component---src-pages-flavors-vanilla-extra-extra-js": () => import("/opt/build/repo/dunk-test/src/pages/flavors/vanilla-extra-extra.js" /* webpackChunkName: "component---src-pages-flavors-vanilla-extra-extra-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/dunk-test/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-index-js": () => import("/opt/build/repo/dunk-test/src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-recipes-recipe-example-js": () => import("/opt/build/repo/dunk-test/src/pages/recipes/recipe-example.js" /* webpackChunkName: "component---src-pages-recipes-recipe-example-js" */),
  "component---src-pages-the-dunkin-connection-js": () => import("/opt/build/repo/dunk-test/src/pages/the-dunkin-connection.js" /* webpackChunkName: "component---src-pages-the-dunkin-connection-js" */),
  "component---src-pages-where-to-buy-js": () => import("/opt/build/repo/dunk-test/src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */)
}

